<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @revokedReload="revokedReload"
              v-bind:heading="$t('card.revoke-title')"
              v-bind:enableAction="selectedItems.length > 0"
              v-bind:selectedItems="selectedItems"
            />
            <header-right
              slot="right-toolbar"
              @revokedReload="revokedReload"
              :enableAction="selectedItems.length > 0"
            />
            <p
              v-if="
                (!myRevokedList || myRevokedList.length <= 0) &&
                !axiosLoading &&
                !networkError
              "
              class="align-center center-align"
            >
              {{ $t("text.revoked-empty") }}
            </p>

            <v-list
              :data-intro="$t('introjs.this-is-revoked-list')"
              two-line
              class="pa-1"
              v-if="!networkError && !axiosLoading && myRevokedList"
            >
              <template v-for="(item, index) in myRevokedList">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="$t(`${'label.' + item.header}`)"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.id_hash"
                  @click.self="
                    showRevokedDetail(item.id_hash)
                  "
                  v-ripple="{ class: `primary--text` }"
                >
                   <label
                    class="mr-2 check--blank"
                  ></label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-on="on"
                        v-bind="attrs"
                        class="d-none d-sm-block mr-2"
                        :data-intro="
                          $t('introjs.animated-avatar-meaning-not-readed')
                        "
                        @click="
                          showRevokedDetail(item.id_hash)
                        "
                      >
                        <v-img :src="item.avatar_path"></v-img>
                      </v-list-item-avatar>
                    </template>
                    <span>{{ item.avatar_label }}</span>
                  </v-tooltip>

                  <v-list-item-content
                    @click="
                       showRevokedDetail(item.id_hash)
                    "
                  >
                    <v-list-item-title>
                      <span
                        :data-intro="$t('introjs.this-is-sender-name')"
                        class="sub-headline"
                      >
                        {{ item.title }}
                      </span>
                      <div
                        class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.has_attachments) == 1"
                              class="att-icon mx-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ iconAttachment }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe === 'eksternal'"
                              href="javascript:void(0)" class="label theme-bg3 text-white f-12">{{
                              $t("badge.external-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.external-letter") }}</span>
                        </v-tooltip>
                        
                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="parseInt(item.urgensi) == 1"
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ iconUrgent }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.urgent") }}</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="isSecret(item.tag)"
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ iconSecret }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.secret") }}</span>
                          </v-tooltip>
                        </div>

                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown &&
                            isSecret(item.tag)
                          "
                          href="javascript:;"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.confidential") }}</a
                        >
                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown &&
                            isUrgent(item.tag)
                          "
                          href="javascript:void(0)"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.urgent") }}</a
                        >

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='bom'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.directors-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.directors-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='masuk'"
                              href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12"
                              >{{ $t("badge.incoming-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='izin_prinsip'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.permit-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.permit-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="parseInt(item.has_delegated) == 1"
                              href="javascript:void(0)"
                              class="label theme-bg text-white f-12"
                              >{{ $t("badge.has-delegated") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.has-delegated") }}</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block"
                    >
                      {{
                        $t("label.number") + `: ${cutJudul(item.subtitle, 150)}`
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-block d-sm-none">
                      {{ cutJudul(item.subtitle, 150) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <span
                    :data-intro="$t('introjs.this-is-sending-date')"
                    @click="
                      showRevokedDetail(item.id_hash)
                    "
                  >
                    {{ $date(item.data_time).format("DD MMM") }}
                  </span>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !myRevokedList">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
              ></v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { isIntroActive, rowPerPage } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    selectedItems: undefined,
    isCheckedAll: false,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("revoked", [
      "revokedList",
      "axiosLoading",
      "filterParams",
      "selectAllRevoked",
    ]),
    myRevokedList() {
      if (!this.revokedList) return [];
      return this.revokedList.data;
    },
    memoIds() {
      if (!this.revokedList) return [];
      return this.revokedList.data.map(({ id_hash }) => id_hash);
    },
  },
  created() {
     this.setFilterParams({
      sortBy: "datetime",
      sortByDesc: "desc",
      perPage: rowPerPage,
      currentPage: 1,
      only: "",
      filter: "",
      from: 0,
      to: 0,
      lastPage: 1,
      total:undefined
    });

    this.revokedReload();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    selectAllRevoked(val) {
      if (this.selectedItems == this.memoIds) return;

      this.setSelectAllRevoked(false);
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val === true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
      this.updateCheckAll();
    },
  },
  methods: {
    ...mapActions("revoked", [
      "loadRevoked",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAllRevoked",
    ]),
    showRevokedDetail(id) {
      setTimeout(() => {
        this.$router.push({
          name: 'revoked.detail',
          params: { id: id },
        });
      }, 300);
    },
    revokedReload() {
      // this.setFilterParams(this.filterParams);
      this.setSelectAllRevoked(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      this.loadRevoked(this.filterParams);

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    isUrgent(val){
      let tagList = ['urgent'];
      return tagList.includes(val);
    },
    isSecret(val){
      let tagList = ['secret'];
      return tagList.includes(val);
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0
      ) {
        this.setSelectAllRevoked(true);
      } else {
        this.setSelectAllRevoked(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
  },
};
</script>